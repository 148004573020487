import React, { useEffect, useState } from "react";
import Paginator from "../../../../../Pagination/Pagination.component";
import { Loader } from "../../../../../../UI/Loaders/Loaders";
import { appInsights } from "../../../../../../config/appInsights";
import useAllAggregatorProducts from "../../../../../../custom-hooks/useAllAggregatorProducts";
import { formatMoney } from "accounting";
import { useDispatch } from "react-redux";
import { showModal } from "../../../../../../redux/actions/modal";

const LegacyAggregatorProduct = () => {
  const [Page, setPage] = useState(1);
  const [PageSize, setPageSize] = useState(10);
  const dispatch = useDispatch();

  //   const history = useHistory();
  const viewLegacyModal = (tableData: any) => {
    dispatch(
      showModal({
        modalIdentifier: "legacyaggregatorproduct",
        legacyaggregatorData: tableData,
      })
    );
  };
  const { data: legacyproductData, status, error } = useAllAggregatorProducts();
  useEffect(() => {
    appInsights.trackPageView({
      name: "LegacyAggregatorProduct.tsx",
      isLoggedIn: true,
    });
  }, []);

  let RenderedComponent = null;

  if (!legacyproductData && status !== "loading") {
    RenderedComponent = (
      <div>
        <div className="grid__padding animated fadeInRight">
          <h4 className="center-txt">
            <i className="fas fa-bell" /> Unable to fetch Legacy Aggregator
            Products
          </h4>
        </div>
      </div>
    );
  } else if (status === "loading") {
    RenderedComponent = (
      <Loader centered={true} text="Loading Legacy aggregator Products..." />
    );
  } else {
    if (error) {
      RenderedComponent = (
        <div>
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There was an issue while fetching
              Legacy Aggregator workload, Please Reload The Page{" "}
            </h4>
          </div>
        </div>
      );
    } else if (
      (legacyproductData && legacyproductData?.length === 0) ||
      !legacyproductData
    ) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> There are currently no Legacy
              Aggregator Products
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <div className="row m-x-sm m-y-2 grid__padding">
          <div className={`table-responsive  `}>
            <table className={`table`}>
              <thead className="bg-lighter-grey">
                <tr>
                  <th>S/N</th>
                  <th>Product Id</th>
                  <th>Aggregator</th>
                  <th>Product Name</th>
                  <th>Product Category</th>
                  <th>Max Tenure</th>
                  <th>Interest Rate</th>
                  <th>Currency</th>
                  <th>Max Amount</th>
                  <th>Repayment Frequency</th>
                  <th>Repayer Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {legacyproductData?.map((request: any, _id: number) => {
                  const {
                    id,
                    aggregator,
                    product_name,
                    productCategoryName,
                    maximum_tenor,
                    interest_rate,
                    currency,
                    maximum_amount,
                    repayment_type,
                    repayer_type_value,
                  } = request;
                  return (
                    <tr key={_id}>
                      <td>{_id + 1}</td>
                      <td>{id}</td>
                      <td>{aggregator}</td>
                      <td>{product_name}</td>
                      <td>{productCategoryName}</td>
                      <td>{maximum_tenor}</td>
                      <td>{interest_rate}</td>
                      <td>{currency}</td>
                      <td>{formatMoney(maximum_amount, currency)}</td>
                      <td>{repayment_type}</td>
                      <td>{repayer_type_value}</td>

                      <td className="cursor-pointer">
                        <span
                          onClick={() => viewLegacyModal(request)}
                          className={`color-blue `}
                        >
                          View Details
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  }

  return (
    <div className="animated fadeInRight">
      <div>
        <h3 className={` page-title`}> Legacy Aggregator Product</h3>
      </div>

      <div className="d-flex flex-row flex-wrap justify-content-start m-l-1 m-t-1">
        <label htmlFor="table-fetch" className="form-group">
          Number of results:{" "}
          <select
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            id="table-fetch"
            className="table-fetch-select m-l-1"
            value={PageSize}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </label>
      </div>

      <div>{RenderedComponent}</div>

      <Paginator
        size={PageSize}
        page={Page}
        count={legacyproductData?.length}
        changeCurrentPage={(pageNumber: number) => setPage(pageNumber)}
      />
    </div>
  );
};

export default LegacyAggregatorProduct;
