import { useEffect, useState } from "react";

// Ajax Methods
import ajaxMethods from "../../../../../../api/ajax-methods";

// Ajax End Points
import * as ajaxEndpoints from "../../../../../../api/ajax-endpoints";

import {
  disbursementType,
  repaymentFrequencys,
  repayerType,
  scheduleType,
} from "../../../../../../models/product-data-model";
import { getDataWithDotNet } from "../../../../../../newApis/dotNetApiMethods";
import { formatMoney } from "../../../../../../helpers/formatter";
import { appInsights } from "../../../../../../config/appInsights";
import useProductNotificationsTypes from "../../../../../../custom-hooks/useProductNotificationsTypes";

const LegacyAggregatorModalSelector = (props) => {
  const [state, setState] = useState({
    scene: "form",
    loading: false,
    productCategoryOptions: [],
    aggregatorOptions: [],
    selectedAggregatorId:
      props?.properties?.legacyaggregatorData?.aggregator_id || "",
    selectedProductId:
      props?.properties?.legacyaggregatorData?.product_category_id || "",
    maxTenure: props?.properties?.legacyaggregatorData?.maximum_tenor || "",
    maxAmount: props?.properties?.legacyaggregatorData?.maximum_amount || "",
    productCode: props?.properties?.legacyaggregatorData?.product_code || "",
    productName: props?.properties?.legacyaggregatorData?.product_name || "",
    InterestRate: props?.properties?.legacyaggregatorData?.interest_rate || "",
    selectedrepaymentFrequency:
      props?.properties?.legacyaggregatorData?.repayment_type || "",
    allRepayerType: [],
    selectedRepayerType:
      props?.properties?.legacyaggregatorData?.repayer_type_id || "",
    repayerType: [],
    alldisbursementTypes: [],
    selectedDisbursementType:
      props?.properties?.legacyaggregatorData?.disbursement_type_id || "",
    selectedScheduleType:
      props?.properties?.legacyaggregatorData?.loan_calculation_method_value ||
      "",
    allScheduleType: [],
    showMessage: false,
    showErrorMessage: false,
    message: "",
    product_id: props?.properties?.legacyaggregatorData?.id || "",
    bankOptionsList: [],
    disbursementChannelOptions: [],
    selectedDisbursementChannel:
      props?.properties?.legacyaggregatorData?.disbursement_channel_id || "",
    repaymentChannelOptions: [],
    selectedRepaymentChannel:
      props?.properties?.legacyaggregatorData?.repayment_channel_id || "",
    moratoriumPeriod:
      props?.properties?.legacyaggregatorData?.moratorium_duration || "",
    bankName: props?.properties?.legacyaggregatorData?.bank_id || "",
    accountNumber:
      props?.properties?.legacyaggregatorData?.account_number || "",
    accountLoading: false,
    error: false,
    repaymentError: false,
    tenant: props?.properties?.legacyaggregatorData?.core_banking_tenant || "",
    bankCode: "",
    accountName:
      props?.properties?.legacyaggregatorData?.bank_account_name || "",
    paystackService:
      props?.properties?.legacyaggregatorData?.use_paystack || "",
    okraService: props?.properties?.legacyaggregatorData?.use_okra || "",
    channelAccountsDetails: false,
    disbursementAccountId:
      props?.properties?.legacyaggregatorData?.dis_channel_acct_id || "",
    coreBankingProductId:
      props?.properties?.legacyaggregatorData?.core_banking_product_id || "",
    coreBankingProductList: false,
    coreBankingDetails: false,
    disbursementChannelAccountsList: false,
    repayChannelAccountsDetails: false,
    repaymentAccountId:
      props?.properties?.legacyaggregatorData?.rep_channel_acct_id || "",
    repaymentChannelAccountsList: false,
    repaymentAccountNumber:
      props?.properties?.legacyaggregatorData?.repayment_account_number || "",
    repaymentAccountLoading: false,
    repaymentAccountName:
      props?.properties?.legacyaggregatorData?.repayment_account_name || "",
    repaymentbankName:
      props?.properties?.legacyaggregatorData?.repayment_bank_id || "",
    repaymentbankOptionsList: [],
    repaymentBankCode: "",
    loanType: props?.properties?.legacyaggregatorData?.loan_type_id || "",
    allowBatchDisbursement:
      props?.properties?.legacyaggregatorData?.allow_batch_disbursement || "",
    maximumRunningLoan:
      props?.properties?.legacyaggregatorData?.maximum_running_loan || "",
    endOfTenorPrincipal:
      props?.properties?.legacyaggregatorData?.repay_principal_at_end_of_tenure.toString() ||
      "",
    currency: props?.properties?.legacyaggregatorData?.currency || "",
    moratorium_type:
      props?.properties?.legacyaggregatorData?.moratorium_type || "",
    require_aggregator_approval:
      props?.properties?.legacyaggregatorData?.require_aggregator_approval ||
      "",
    require_bvn_verification:
      props?.properties?.legacyaggregatorData?.require_bvn_verification || "",
    require_deal_slip:
      props?.properties?.legacyaggregatorData?.require_deal_slip || "",
    amortization_type:
      props?.properties?.legacyaggregatorData?.amortization_type || "",
    moratoriumTypeList: [],
    tenantList: [],
    selectedProductNotificationType:
      props?.properties?.legacyaggregatorData?.product_notification_type || "",
  });

  const { data: _productNotificationTypes } = useProductNotificationsTypes();

  useEffect(() => {
    getAggregatorName();
    getProductCategories();
    getallDisbursementTypes();
    getAllRepaymentFrequency();
    getAllRepayerType();
    getAllScheduleType();
    getBankList();
    getDisbursementChannels();
    getRepaymentChannels();
    getLoanType();
    getMoratoriumTypes();
    getTenants();

    if (state.selectedDisbursementChannel) {
      const channelId = state.selectedDisbursementChannel;
      getDisbursementChannelAccounts(channelId);
    }

    if (state.selectedRepaymentChannel) {
      const repaymentChannelId = state.selectedRepaymentChannel;
      getRepaymentChannelAccounts(repaymentChannelId);
    }
  }, []);

  useEffect(() => {
    if (props?.properties?.legacyaggregatorData?.core_banking_tenant) {
      getCoreBankingProductId(
        props?.properties?.legacyaggregatorData?.core_banking_tenant
      );
    }
  }, [props?.properties?.legacyaggregatorData?.core_banking_tenant]);

  // fetch core banking tenants
  const getTenants = () => {
    ajaxMethods
      .get(ajaxEndpoints.GET_CORE_BANKING_TENANT_INFO)
      .then((response) => {
        const corebankingTenants = response?.data;

        if (response?.status_code === 200) {
          const options = corebankingTenants.map((_tenant) => {
            return (
              <option value={_tenant?.tenant_id} key={_tenant?.id}>
                {_tenant?.full_name}
              </option>
            );
          });

          setState((prev) => {
            return {
              ...prev,
              tenantList: options,
            };
          });
        }
      });
  };

  // fetch bank list
  const getBankList = () => {
    ajaxMethods
      .get(ajaxEndpoints.GET_BANK_LIST)
      .then((response) => {
        if (response.status_code === 200 || response.status_code === 201) {
          let bankList = response.data;
          const bankOptionsList = bankList.map((data, index) => {
            return (
              <option value={data.id} key={index}>
                {data.name}
              </option>
            );
          });
          setState((prev) => ({ ...prev, bankOptionsList: bankOptionsList }));
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AggregatorProductModal.js",
          },
        });
      });
  };
  // fetch product category list
  const getProductCategories = () => {
    ajaxMethods
      .get(ajaxEndpoints.PRODUCT_CATEGORY_LIST)
      .then((response) => {
        let productCategoryList = [];

        if (response.data.length > 0) {
          productCategoryList = [...response.data];
        } else {
          productCategoryList = [
            {
              code: 0,
              productCategoryName: "No Categories Yet",
            },
          ];
        }
        const productCategoryOptions = productCategoryList.map(
          (ecosystem, index) => {
            const { code, id, productCategoryName } = ecosystem;
            return (
              <option key={index} id={code} value={id}>
                {productCategoryName}
              </option>
            );
          }
        );

        setState((prev) => ({
          ...prev,
          productCategoryOptions: productCategoryOptions,
        }));
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AggregatorProductModal.js",
          },
        });
      });
  };
  //fetch aggregators
  const getAggregatorName = () => {
    ajaxMethods
      .get(ajaxEndpoints.ECOSYSTEMS)
      .then((response) => {
        let categoryList = [];

        if (response.data.length > 0) {
          categoryList = [...response.data];
        } else {
          categoryList = [
            {
              aggregator_id: 0,
              category_name: "No Categories Yet",
              category_slug: "No Categories Yet",
            },
          ];
        }
        const aggregatorOptions = categoryList.map((ecosystem, index) => {
          const { aggregator_id, biz_name } = ecosystem;
          return (
            <option key={index} id={aggregator_id} value={aggregator_id}>
              {biz_name}
            </option>
          );
        });
        setState((prev) => ({
          ...prev,
          aggregatorOptions: aggregatorOptions,
        }));
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AggregatorProductModal.js",
          },
        });
      });
  };

  // get all Repayment Frequencys
  const getAllRepaymentFrequency = () => {
    const allrepaymentFrequencys = repaymentFrequencys.map(
      (ecosystem, index) => {
        const { repayment_Type, repaymentFrequencyName } = ecosystem;
        return (
          <option key={index} id={repayment_Type} value={repayment_Type}>
            {repaymentFrequencyName}
          </option>
        );
      }
    );
    setState((prev) => ({
      ...prev,
      allrepaymentFrequencys: allrepaymentFrequencys,
    }));
  };

  // get all Schedule Type
  const getAllScheduleType = () => {
    const allScheduleType = scheduleType.map((ecosystem, index) => {
      const { schedule_Type, scheduleTypeName } = ecosystem;

      return (
        <option key={index} id={schedule_Type} value={scheduleTypeName}>
          {scheduleTypeName}
        </option>
      );
    });
    setState((prev) => ({
      ...prev,
      allScheduleType: allScheduleType,
    }));
  };

  // get all disbursement types
  const getallDisbursementTypes = () => {
    const alldisbursementTypes = disbursementType.map((ecosystem, index) => {
      const { disbursementId, disbursementType } = ecosystem;
      return (
        <option key={index} id={disbursementId} value={disbursementId}>
          {disbursementType}
        </option>
      );
    });

    setState((prev) => ({
      ...prev,
      alldisbursementTypes: alldisbursementTypes,
    }));
  };

  // get all Repayer Type
  const getAllRepayerType = () => {
    const allRepayerType = repayerType.map((ecosystem, index) => {
      const { repayerTypeId, repayerTypeName } = ecosystem;
      return (
        <option key={index} id={repayerTypeId} value={repayerTypeId}>
          {repayerTypeName}
        </option>
      );
    });
    setState((prev) => ({
      ...prev,
      allRepayerType: allRepayerType,
    }));
  };

  const handleOptionSelect = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
      showMessage: false,
      showErrorMessage: false,
      error: false,
      repaymentError: false,
    }));
    if (e.target.name === "tenant") {
      getCoreBankingProductId(e.target.value);
    }

    if (e.target.name === "coreBankingProductId") {
      let corebankingCurrency = state.coreBankingDetails.find(
        (eachDetails) => eachDetails.id === Number(e.target.value)
      );

      setState((prev) => ({
        ...prev,
        currency: corebankingCurrency.currencyCode,
      }));
    }
  };

  const handleRepayChannelOptionSelect = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
      showMessage: false,
      showErrorMessage: false,
      repaymentError: false,
      repayChannelAccountsDetails: false,
      // repaymentAccountId:
      //   props?.properties?.legacyaggregatorData?.dis_channel_acct_id || "",
      repaymentChannelAccountsList: false,
    }));
    getRepaymentChannelAccounts(e.target.value);
  };

  const handleChannelOptionSelect = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
      showMessage: false,
      showErrorMessage: false,
      error: false,
      repaymentError: false,
      channelAccountsDetails: false,
      disbursementAccountId:
        props?.properties?.legacyaggregatorData?.dis_channel_acct_id || "",
      disbursementChannelAccountsList: false,
    }));
    getDisbursementChannelAccounts(e.target.value);
  };

  const handleChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
      showMessage: false,
      showErrorMessage: false,
    }));
  };

  // fetch Disbursement channel list
  const getDisbursementChannels = () => {
    ajaxMethods
      .get(ajaxEndpoints.GET_DISBURSEMENT_CHANNEL_LIST)
      .then((response) => {
        let disbursementChannelList = [];
        if (response.data.length > 0) {
          disbursementChannelList = [...response.data];
        } else {
          disbursementChannelList = [
            {
              code: 0,
              productCategoryName: "No Categories Yet",
            },
          ];
        }
        const disbursementChannelListOptions = disbursementChannelList.map(
          (ecosystem, index) => {
            const { id, channel_name } = ecosystem;
            return (
              <option key={index} id={id} value={id}>
                {channel_name}
              </option>
            );
          }
        );

        setState((prev) => ({
          ...prev,
          disbursementChannelOptions: disbursementChannelListOptions,
        }));
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AggregatorProductModal.js",
          },
        });
      });
  };
  // fetch loan types
  const getLoanType = async () => {
    const data = await getDataWithDotNet(ajaxEndpoints.GET_LOAN_TYPE);
    let loanType = data.data;
    const loanTypesListOptions = loanType.map((ecosystem, index) => {
      const { id, name } = ecosystem;
      return (
        <option key={index} id={id} value={id}>
          {name}
        </option>
      );
    });

    setState((prev) => ({
      ...prev,
      loanTypeList: loanTypesListOptions,
    }));
  };
  // fetch repayment channel list
  const getRepaymentChannels = () => {
    ajaxMethods
      .get(ajaxEndpoints.GET_REPAYMENT_CHANNEL_LIST)
      .then((response) => {
        let repaymentChannelList = [];
        if (response.data.length > 0) {
          repaymentChannelList = [...response.data];
        } else {
          repaymentChannelList = [
            {
              code: 0,
              productCategoryName: "No Categories Yet",
            },
          ];
        }
        const repaymentChannelListOptions = repaymentChannelList.map(
          (ecosystem, index) => {
            const { id, channel_name } = ecosystem;
            return (
              <option key={index} id={id} value={id}>
                {channel_name}
              </option>
            );
          }
        );

        setState((prev) => ({
          ...prev,
          repaymentChannelOptions: repaymentChannelListOptions,
        }));
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AggregatorProductModal.js",
          },
        });
      });
  };

  // validate account number
  const onRepayAccountNumberInput = (e) => {
    setState((prev) => ({
      ...prev,
      repaymentError: false,
      repaymentAccountNumber: "",
    }));
    let number = e.target.value;

    if (state.repaymentbankName === "") {
      setState((prev) => ({ ...prev, repaymentError: `Please select a bank` }));
      return;
    }

    setState((prev) => ({ ...prev, repaymentAccountNumber: number }));
    if (number.length === 10 && !state.repaymentAccountLoading) {
      setState((prev) => ({ ...prev, repaymentAccountLoading: true }));
      //validate account number and bank name
      let data = {
        bank_code: state.repaymentBankCode,
        bank_account_num: number,
      };
      validateRepaymentAccount(data);
    }
  };

  const validateRepaymentAccount = (data) => {
    ajaxMethods
      .post(ajaxEndpoints.VALIDATE_ACCOUNT_NUMBER, data)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setState((prev) => ({
            ...prev,
            repaymentAccountName: response.data.bank_account_name,
            repaymentAccountLoading: false,
          }));
        } else if (response.status === 400) {
          setState((prev) => ({
            ...prev,
            repaymentError: response.data.message,
            repaymentAccountLoading: false,
          }));
        }
        if (response.status >= 500) {
          setState((prev) => ({
            ...prev,
            repaymentError: "Unable to connect to Advancly services.",
            repaymentAccountLoading: false,
          }));
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AggregatorProductModal.js",
          },
        });
      });
  };

  // validate account number
  const onAccountNumberInput = (e) => {
    setState((prev) => ({ ...prev, error: false, accountName: "" }));
    let number = e.target.value;

    if (state.bankName === "") {
      setState((prev) => ({ ...prev, error: `Please select a bank` }));
      return;
    }

    setState((prev) => ({ ...prev, accountNumber: number }));
    if (number.length === 10 && !state.accountLoading) {
      setState((prev) => ({ ...prev, accountLoading: true }));
      let data = {
        bank_code: state.bankCode,
        bank_account_num: number,
      };
      validateAccount(data);
    }
  };

  const validateAccount = (data) => {
    ajaxMethods
      .post(ajaxEndpoints.VALIDATE_ACCOUNT_NUMBER, data)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setState((prev) => ({
            ...prev,
            accountName: response.data.bank_account_name,
            accountLoading: false,
          }));
        } else if (response.status === 400) {
          setState((prev) => ({
            ...prev,
            error: response.data.message,
            accountLoading: false,
          }));
        }
        if (response.status >= 500) {
          setState((prev) => ({
            ...prev,
            error: "Unable to connect to Advancly services.",
            accountLoading: false,
          }));
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AggregatorProductModal.js",
          },
        });
      });
  };
  // fetch accounts for a specific disburse channel
  const getDisbursementChannelAccounts = (number) => {
    setState((prev) => ({ ...prev, loading: true }));
    ajaxMethods
      .get(
        ajaxEndpoints.GET_DISBURSEMENT_ACCOUNTS +
          `?disbursement_channel_id=${number}`
      )
      .then((response) => {
        setState((prev) => ({ ...prev, loading: false }));
        if (response.status_code === 200 || response.status_code === 201) {
          if (response.data) {
            setState((prev) => ({
              ...prev,
              channelAccountsDetails: response.data,
            }));
          } else {
            setState((prev) => ({ ...prev, channelAccountsDetails: false }));
          }
          const disbursementChannelAccountsList =
            state.channelAccountsDetails.map((ecosystem, index) => {
              const { id, account_number, account_name, bank_name } = ecosystem;
              const accountData = `${account_number} / ${account_name} / ${bank_name}`;
              return (
                <option key={index} id={id} value={id}>
                  {accountData}
                </option>
              );
            });
          setState((prev) => ({
            ...prev,
            disbursementChannelAccountsList: disbursementChannelAccountsList,
          }));
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AggregatorProductModal.js",
          },
        });
      });
  };
  // fetch accounts for a specific disburse channel
  const getRepaymentChannelAccounts = (number) => {
    setState((prev) => ({ ...prev, loading: true }));
    ajaxMethods
      .get(
        ajaxEndpoints.GET_REPAYMENT_ACCOUNTS + `?repayment_channel_id=${number}`
      )
      .then((response) => {
        setState((prev) => ({ ...prev, loading: false }));
        if (response.status_code === 200 || response.status_code === 201) {
          if (response.data) {
            setState((prev) => ({
              ...prev,
              repayChannelAccountsDetails: response.data,
            }));
          } else {
            setState((prev) => ({
              ...prev,
              repayChannelAccountsDetails: false,
            }));
          }
          const repaymentChannelAccountsList =
            state.repayChannelAccountsDetails.map((ecosystem, index) => {
              const { id, account_number, account_name, bank_name } = ecosystem;
              const accountData = `${account_number} / ${account_name} / ${bank_name}`;
              return (
                <option key={index} id={id} value={id}>
                  {accountData}
                </option>
              );
            });
          setState((prev) => ({
            ...prev,
            repaymentChannelAccountsLists: repaymentChannelAccountsList,
          }));
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AggregatorProductModal.js",
          },
        });
      });
  };

  const getMoratoriumTypes = () => {
    ajaxMethods
      .get(ajaxEndpoints.GET_MORATORIUM_TYPES)
      .then((response) => {
        if (response.status_code === 200) {
          const moratoriumTypeList = response.data.map((types, index) => {
            const { id, name } = types;
            return (
              <option key={index} id={id} value={id}>
                {name}
              </option>
            );
          });
          setState((prev) => ({
            ...prev,
            moratoriumTypeList: moratoriumTypeList,
          }));
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AggregatorProductModal.js",
          },
        });
      });
  };

  // fetch product category list
  const getBankCode = (e) => {
    let number = e.target.value;
    ajaxMethods
      .get(ajaxEndpoints.GET_BANK_LIST + `?bank_id=${number}`)
      .then((response) => {
        if (response.status_code === 200 || response.status_code === 201) {
          let res = response.data[0];

          setState((prev) => ({ ...prev, bankCode: res.code }));
        } else {
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AggregatorProductModal.js",
          },
        });
      });
  };
  // fetch product category list
  const getRepaymentBankCode = (e) => {
    let number = e.target.value;
    ajaxMethods
      .get(ajaxEndpoints.GET_BANK_LIST + `?bank_id=${number}`)
      .then((response) => {
        if (response.status_code === 200 || response.status_code === 201) {
          let res = response.data[0];

          setState((prev) => ({ ...prev, repaymentBankCode: res.code }));
        } else {
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AggregatorProductModal.js",
          },
        });
      });
  };

  // fetch accounts for a product id by specific loan type
  const getCoreBankingProductId = (tenantId) => {
    setState((prev) => ({ ...prev, loading: true }));
    ajaxMethods
      .get(ajaxEndpoints.GET_CBA_PRODUCT_ID + `?TenantId=${tenantId}`)
      .then((response) => {
        setState((prev) => ({ ...prev, loading: false }));

        if (response) {
          setState((prev) => ({ ...prev, coreBankingDetails: response }));

          const coreBankingDetailsList = response.map((products, index) => {
            const { id, name } = products;
            return (
              <option key={index} id={id} value={id}>
                {name}
              </option>
            );
          });
          setState((prev) => ({
            ...prev,
            coreBankingProductList: coreBankingDetailsList,
          }));
        }
      })
      .catch((error) => {
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "AggregatorProductModal.js",
          },
        });
      });
  };

  const {
    productCategoryOptions,
    aggregatorOptions,
    selectedAggregatorId,
    selectedProductId,
    maxTenure,
    maxAmount,
    productCode,
    productName,
    InterestRate,
    selectedrepaymentFrequency,
    allrepaymentFrequencys,
    selectedRepayerType,
    allRepayerType,
    selectedDisbursementType,
    alldisbursementTypes,
    selectedScheduleType,
    allScheduleType,
    showMessage,
    showErrorMessage,
    message,
    bankOptionsList,
    disbursementChannelOptions,
    repaymentChannelOptions,
    selectedDisbursementChannel,
    selectedRepaymentChannel,
    moratoriumPeriod,
    bankName,
    accountNumber,
    error,
    accountLoading,
    tenant,
    paystackService,
    okraService,
    disbursementAccountId,
    disbursementChannelAccountsList,
    repaymentAccountId,
    repaymentChannelAccountsLists,
    repaymentError,
    repaymentAccountNumber,
    repaymentAccountLoading,
    repaymentAccountName,
    repaymentbankName,
    loanType,
    loanTypeList,
    coreBankingProductId,
    coreBankingProductList,
    allowBatchDisbursement,
    maximumRunningLoan,
    moratorium_type,
    moratoriumTypeList,
    currency,
    require_aggregator_approval,
    require_bvn_verification,
    require_deal_slip,
    amortization_type,
    tenantList,
    selectedProductNotificationType,
  } = state;

  return (
    <div
      className="Modal__dialog  lg-modal animated slideInDown"
      role="document"
    >
      <div className="IssueModal Modal__content" style={{ overflow: "hidden" }}>
        <div className="Modal__body">
          <h3
            className="Modal__title m-b-1 p-x-2 p-y-1"
            style={{ backgroundColor: "#713fff", color: "#fff" }}
          >
            Legacy Aggregator's Product
          </h3>

          <form>
            <div className="row">
              <div className="col-xs-4">
                <label>Aggregator</label>

                <div className="form-group inputWithIcon">
                  <select
                    className="round user__form form-control"
                    name="selectedAggregatorId"
                    placeholder="Select Aggregator"
                    id="selectedAggregatorId"
                    value={selectedAggregatorId}
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                    onChange={(e) => handleOptionSelect(e)}
                  >
                    <option value="">Select Aggregator</option>
                    {aggregatorOptions}
                  </select>
                  <i className="adv-aggregator-icon fas fa-building"></i>{" "}
                </div>
              </div>

              <div className="col-xs-4">
                <label>Product Category</label>
                <div className="form-group inputWithIcon">
                  <select
                    className="round user__form form-control"
                    name="selectedProductId"
                    placeholder="Select a Product"
                    id="selectedProductId"
                    value={selectedProductId}
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                    onChange={(e) => handleOptionSelect(e)}
                  >
                    <option value="">Select a Product</option>
                    {productCategoryOptions}
                  </select>
                  <i className="adv-aggregator-icon fas fa-university"></i>{" "}
                </div>
              </div>
              <div className="col-xs-4">
                <label>Product Code</label>
                <div className="form-group inputWithIcon">
                  <input
                    type="text"
                    className="form-control"
                    name="productCode"
                    value={productCode}
                    onChange={handleChange}
                    readOnly
                  />
                  <i className="adv-aggregator-icon fas fa-code"></i>{" "}
                </div>
              </div>
            </div>

            <div className="row m-t-1">
              <div className="col-xs-4">
                <label>Product Name</label>
                <div className="form-group inputWithIcon">
                  <input
                    type="text"
                    className="form-control"
                    name="productName"
                    value={productName}
                    readOnly
                    onChange={handleChange}
                  />
                  <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
                </div>
              </div>
              <div className="col-xs-4">
                <label>Interest Rate</label>
                <div className="form-group inputWithIcon">
                  <input
                    type="number"
                    className="form-control"
                    value={InterestRate}
                    name="InterestRate"
                    readOnly
                    onChange={handleChange}
                  />
                  <i className="adv-aggregator-icon fas fa-percent"></i>{" "}
                </div>
              </div>
              <div className="col-xs-4">
                <label>Maximum Tenure</label>
                <div className="form-group inputWithIcon">
                  <input
                    type="number"
                    className="form-control"
                    name="maxTenure"
                    value={maxTenure}
                    readOnly
                    onChange={handleChange}
                  />
                  <i className="adv-aggregator-icon fas fa-clock"></i>{" "}
                </div>
              </div>
            </div>
            <div className="row m-t-1">
              <div className="col-xs-4">
                <label>Schedule Type</label>
                <div className="form-group ">
                  <select
                    className="round user__form form-control"
                    name="selectedScheduleType"
                    id="selectedScheduleType"
                    value={selectedScheduleType}
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                    onChange={(e) => handleOptionSelect(e)}
                  >
                    <option value=""></option>
                    {allScheduleType}
                  </select>
                </div>
              </div>
              <div className="col-xs-4">
                <label>Repayer Type</label>
                <div className="form-group inputWithIcon">
                  <select
                    className="round user__form form-control"
                    name="selectedRepayerType"
                    id="selectedRepayerType"
                    value={selectedRepayerType}
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                    onChange={(e) => handleOptionSelect(e)}
                  >
                    <option value=""></option>
                    {allRepayerType}
                  </select>
                </div>
              </div>
              <div className="col-xs-4">
                <label>Disbursement Type</label>
                <div className="form-group inputWithIcon">
                  <select
                    className="round user__form form-control"
                    name="selectedDisbursementType"
                    id="selectedDisbursementType"
                    value={selectedDisbursementType}
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                    onChange={(e) => handleOptionSelect(e)}
                  >
                    <option value=""></option>
                    {alldisbursementTypes}
                  </select>
                  <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
                </div>
              </div>
            </div>
            <div className="row m-t-2">
              <div className="col-xs-4">
                <label>Max Amount {formatMoney(maxAmount, currency)}</label>
                <div className="form-group inputWithIcon">
                  <input
                    type="number"
                    className="form-control"
                    data-form="maxAmount"
                    name="maxAmount"
                    value={maxAmount}
                    readOnly
                    onChange={handleChange}
                  />
                  <i className="adv-aggregator-icon fas fa-money"></i>{" "}
                </div>
              </div>
              <div className="col-xs-4">
                <label>Repayment Frequency</label>
                <div className="form-group ">
                  <select
                    className="round user__form form-control"
                    name="selectedrepaymentFrequency"
                    placeholder="Select a Product"
                    id="selectedrepaymentFrequency"
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                    value={selectedrepaymentFrequency}
                    onChange={(e) => handleOptionSelect(e)}
                  >
                    <option value=""></option>
                    {allrepaymentFrequencys}
                  </select>
                </div>
              </div>
              <div className="col-xs-4">
                <label>Disbursement Channel</label>
                <div className="form-group">
                  <select
                    className="round user__form form-control"
                    name="selectedDisbursementChannel"
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                    value={selectedDisbursementChannel}
                    onChange={(e) => handleChannelOptionSelect(e)}
                  >
                    <option value=""></option>
                    {disbursementChannelOptions}
                  </select>{" "}
                </div>
              </div>
            </div>
            <div className="row m-t-2">
              <div className="col-xs-4">
                <label>Disbursement Channel Account</label>
                <div className="form-group inputWithIcon">
                  <select
                    className="round user__form form-control"
                    name="disbursementAccountId"
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                    value={disbursementAccountId}
                    disabled={!disbursementChannelAccountsList}
                    onChange={(e) => handleOptionSelect(e)}
                  >
                    <option value=""></option>
                    {disbursementChannelAccountsList}
                  </select>
                  <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
                </div>
              </div>
              <div className="col-xs-4">
                <label>Repayment Channel </label>
                <div className="form-group inputWithIcon">
                  <select
                    className="round user__form form-control"
                    name="selectedRepaymentChannel"
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                    value={selectedRepaymentChannel}
                    onChange={(e) => handleRepayChannelOptionSelect(e)}
                  >
                    <option value=""></option>
                    {repaymentChannelOptions}
                  </select>
                  <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
                </div>
              </div>
              <div className="col-xs-4">
                <label>Repayment Channel Account</label>
                <div className="form-group inputWithIcon">
                  <select
                    className="round user__form form-control"
                    name="repaymentAccountId"
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                    value={repaymentAccountId}
                    disabled={!repaymentChannelAccountsLists}
                    onChange={(e) => handleOptionSelect(e)}
                  >
                    <option value=""></option>
                    {repaymentChannelAccountsLists}
                  </select>
                  <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
                </div>
              </div>
            </div>
            <div className="row m-t-2">
              <div className="col-xs-4">
                <label>Okra Service</label>
                <div className="form-group">
                  <select
                    className="round user__form form-control form-load"
                    name="okraService"
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                    value={okraService}
                    onChange={(e) => handleOptionSelect(e)}
                  >
                    <option></option>
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                  </select>
                </div>
              </div>

              <div className="col-xs-4">
                <label>Paystack Service</label>
                <div className="form-group">
                  <select
                    type="text"
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                    className="round user__form form-control form-load"
                    name="paystackService"
                    value={paystackService}
                    // onChange={(e) => handleOptionSelect(e)}
                  >
                    <option></option>
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                  </select>
                </div>
              </div>
              <div className="col-xs-4">
                <label>Core Banking Tenant</label>

                <div className="form-group">
                  <select
                    type="text"
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                    className="round user__form form-control form-load"
                    name="tenant"
                    value={tenant}
                    onChange={(e) => handleOptionSelect(e)}
                  >
                    <option value=""></option>
                    {tenantList}
                  </select>
                </div>
              </div>
            </div>

            <div className="row m-t-2">
              <div className="col-xs-4">
                <label>Batch Disbursement</label>
                <div className="form-group inputWithIcon">
                  <select
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                    className="round user__form form-control"
                    name="allowBatchDisbursement"
                    value={allowBatchDisbursement}
                    onChange={(e) => handleOptionSelect(e)}
                  >
                    <option></option>
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                  </select>
                </div>
              </div>

              <div className="col-xs-4">
                <label>Loan Type </label>
                <div className="form-group inputWithIcon">
                  <select
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                    className="
                    round user__form form-control"
                    name="loanType"
                    value={loanType}
                    onChange={(e) => handleOptionSelect(e)}
                  >
                    <option value=""></option>
                    {loanTypeList}
                  </select>
                  <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
                </div>
              </div>

              <div className="col-xs-4">
                <label>Core Banking Product</label>
                <div className="form-group inputWithIcon">
                  <select
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                    className="round user__form form-control"
                    name="coreBankingProductId"
                    value={coreBankingProductId}
                    disabled={!coreBankingProductList}
                    onChange={(e) => handleOptionSelect(e)}
                  >
                    <option value=""></option>
                    {coreBankingProductList}
                  </select>
                </div>
              </div>
            </div>

            <div className="row m-t-2">
              <div className="col-xs-4">
                <label>Currency</label>

                <div className="form-group inputWithIcon">
                  <input
                    type="text"
                    className="form-control"
                    name="currency"
                    value={currency}
                    readOnly
                  />

                  <i className="adv-aggregator-icon fas fa-money "></i>
                </div>
              </div>

              <div className="col-xs-4">
                <label>Moratorium Type</label>
                <div className="form-group inputWithIcon">
                  <select
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                    className="round user__form form-control"
                    name="moratorium_type"
                    value={
                      selectedrepaymentFrequency ==
                      repaymentFrequencys[3]?.repayment_Type
                        ? 1
                        : moratorium_type
                    }
                    onChange={(e) => handleOptionSelect(e)}
                    disabled={
                      selectedrepaymentFrequency ===
                      repaymentFrequencys[3]?.repayment_Type
                    }
                  >
                    <option value=""></option>
                    {moratoriumTypeList}
                  </select>
                </div>
              </div>

              {(moratorium_type === 3 || moratorium_type === "3") &&
                selectedrepaymentFrequency !==
                  repaymentFrequencys[3]?.repayment_Type && (
                  <div className="col-xs-4">
                    <label>Moratorium Period</label>
                    <div className="form-group">
                      <input
                        readOnly
                        type="number"
                        className="form-control"
                        data-form="moratoriumPeriod"
                        name="moratoriumPeriod"
                        value={moratoriumPeriod}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                )}

              <div className="col-xs-4">
                <label>Max Running Loan(s)</label>
                <div className="form-group inputWithIcon">
                  <input
                    readOnly
                    type="number"
                    className="form-control"
                    name="maximumRunningLoan"
                    value={maximumRunningLoan}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="row m-t-2">
              <div className="col-xs-4">
                <label>Require Aggr. Approval</label>
                <div className="form-group inputWithIcon">
                  <select
                    className="round user__form form-control"
                    name="require_aggregator_approval"
                    value={require_aggregator_approval}
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                    onChange={(e) => handleOptionSelect(e)}
                  >
                    <option value=""></option>
                    <option value={true}>TRUE</option>
                    <option value={false}>FALSE</option>
                  </select>
                </div>
              </div>

              <div className="col-xs-4">
                <label>Amortization Type</label>
                <div className="form-group inputWithIcon">
                  <select
                    className="round user__form form-control"
                    name="amortization_type"
                    value={amortization_type}
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                    onChange={(e) => handleOptionSelect(e)}
                  >
                    <option value=""></option>
                    <option value={0}>Equal Principle Payments</option>
                    <option value={1}>Equal Installments</option>
                  </select>
                </div>
              </div>

              <div className="col-xs-4">
                <label>Allow Sending of Deal Slip</label>
                <div className="form-group inputWithIcon">
                  <select
                    className="round user__form form-control"
                    name="require_deal_slip"
                    value={require_deal_slip}
                    onChange={(e) => handleOptionSelect(e)}
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                  >
                    <option value=""></option>
                    <option value={true}>TRUE</option>
                    <option value={false}>FALSE</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="row m-t-2">
              <div className="col-xs-4">
                <label>Require BVN Verification</label>
                <div className="form-group inputWithIcon">
                  <select
                    className="round user__form form-control"
                    name="require_bvn_verification"
                    value={require_bvn_verification}
                    // onChange={(e) => handleOptionSelect(e)}
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                  >
                    <option value=""></option>
                    <option value={true}>TRUE</option>
                    <option value={false}>FALSE</option>
                  </select>
                </div>
              </div>

              <div className="col-xs-4">
                <label>Notification Types</label>
                <div className="form-group">
                  <select
                    className="round user__form form-control"
                    name="selectedProductNotificationType"
                    value={selectedProductNotificationType}
                    onChange={(e) => handleOptionSelect(e)}
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                  >
                    <option value="">Select Type</option>
                    {_productNotificationTypes &&
                      _productNotificationTypes?.data.map((_item, index) => (
                        <option value={_item?.name} key={index}>
                          {_item?.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>

            {repaymentError && (
              <span className="text-danger p-b-2">{repaymentError}</span>
            )}
            <div className="row m-t-2">
              <div className="col-xs-6">
                <label>Repayment Bank Name </label>
                <div className="form-group inputWithIcon">
                  <select
                    className="round user__form form-control"
                    name="repaymentbankName"
                    value={repaymentbankName}
                    onChange={(e) => handleOptionSelect(e)}
                    onInput={getRepaymentBankCode}
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                  >
                    <option value=""></option>
                    {bankOptionsList}
                  </select>
                  <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
                </div>
              </div>
              <div className="col-xs-6">
                <label>Repayment Account number</label>
                <div className="form-group">
                  <input
                    type="number"
                    className="form-control"
                    data-form="repaymentAccountNumber"
                    name="repaymentAccountNumber"
                    maxLength={10}
                    value={repaymentAccountNumber}
                    onChange={handleChange}
                    onInput={onRepayAccountNumberInput}
                    readOnly
                  />{" "}
                </div>
              </div>
            </div>
            <div className="row m-t-1">
              <div className="col-xs-6">
                <div className="form-group">
                  <label>Repayment Account name</label>{" "}
                  {repaymentAccountLoading && (
                    <span className="text-primary">Loading</span>
                  )}
                  <input
                    type="text"
                    name="repaymentAccountName"
                    className="form-control"
                    value={repaymentAccountName}
                    defaultValue={repaymentAccountName}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-xs-6">
                <label>Disbursement Bank Name </label>
                <div className="form-group inputWithIcon">
                  <select
                    className="round user__form form-control"
                    name="bankName"
                    value={bankName}
                    onChange={(e) => handleOptionSelect(e)}
                    onInput={getBankCode}
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "#f0f0f0",
                    }}
                  >
                    <option value=""></option>
                    {bankOptionsList}
                  </select>
                  <i className="adv-aggregator-icon fas fa-credit-card"></i>{" "}
                </div>
              </div>
            </div>
            {error && <span className="text-danger p-b-2">{error}</span>}
            <div className="row m-t-1">
              <div className="col-xs-6">
                <label>Disbursement Account number</label>
                <div className="form-group">
                  <input
                    type="number"
                    className="form-control"
                    data-form="accountNumber"
                    name="accountNumber"
                    maxLength={10}
                    value={accountNumber}
                    onChange={handleChange}
                    onInput={onAccountNumberInput}
                    readOnly
                  />{" "}
                </div>
              </div>
              <div className="col-xs-6">
                <label>Disbursement Account name</label>{" "}
                {accountLoading && (
                  <span className="text-primary">Loading</span>
                )}
                <input
                  type="text"
                  name="accountName"
                  className="form-control"
                  value={state.accountName}
                  defaultValue={state.accountName}
                  disabled
                />
              </div>
            </div>

            {showMessage && (
              <div className="row m-t-1 m-b-1">
                <div className="col-xs-4"></div>
                <div className="col-xs-4">
                  <span className="text-success p-b-2">{message}</span>
                </div>
                <div className="col-xs-4"></div>
              </div>
            )}
            {showErrorMessage && (
              <div className="row m-t-1 m-b-1">
                <div className="col-xs-4"></div>
                <div className="col-xs-4">
                  <span className="text-danger p-b-2">{message}</span>
                </div>
                <div className="col-xs-4"></div>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default LegacyAggregatorModalSelector;
