import React from "react";
import { MDBTableHead, MDBTable, MDBTableBody } from "mdbreact";
import { Loader } from "../../../UI/Loaders/Loaders";
import { accurateQuantifier } from "../../../helpers/accurateQuantifier";
import { currencyFormatter } from "../../../helpers/stringControllers";
import { attachClassNameToTag } from "../../../helpers/attachClassNameToTag";
import useFinalDisbursements from "../../../custom-hooks/useFinalDisbursements";

type IdisbursementProps = {
  refName: string;
};
const Disbursement = ({ refName }: IdisbursementProps) => {
  const { data, error, isLoading } = useFinalDisbursements({
    loan_application_ref: refName,
  });

  let RenderedComponent = null;
  if (error) {
    RenderedComponent = (
      <div className="center-container">
        <div className="centerLoader">
          <div className="grid__padding animated fadeInRight">
            <h4 className="center-txt">
              <i className="fas fa-bell" /> Unable to fetch Disbursement Data
            </h4>
          </div>
        </div>
      </div>
    );
  } else if (isLoading) {
    RenderedComponent = (
      <Loader text="Loading Disbursements ..." centered={true} />
    );
  } else if (data) {
    if (data?.data?.length === 0) {
      RenderedComponent = (
        <div className="centerLoader">
          <div className="mt-40 tfx-user-form-wrapper box-effect full-border white-bg animated fadeInRight">
            <h4 className="center-txt p-y-5">
              <i className="fas fa-bell" /> No Disbursement Available
            </h4>
          </div>
        </div>
      );
    } else {
      RenderedComponent = (
        <>
          <MDBTable
            responsive
            minimal="true"
            striped
            bordered
            hover
            id="table-to-xls"
          >
            <MDBTableHead>
              <tr>
                <th>S/N</th>
                <th>Loan Ref</th>
                <th>Product Name</th>
                <th>Tenure</th>
                <th>Repayment Frequency</th>
                <th>Amount</th>
                <th>Amount to Repay</th>
                <th>Effective Date</th>
                <th>Due Date</th>
                <th>Loan Status</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {data &&
                data?.data &&
                data?.data.map((_data: any, _id: number) => {
                  const {
                    loan_amount,
                    loan_amount_repay,
                    loan_due_date,
                    status,
                    loan_effective_date,
                    loan_ref,
                    loan_tenure,
                    repayment_type,
                    currency,
                    product_name,
                  } = _data;
                  return (
                    <tr key={_id}>
                      <td>{_id + 1}</td>
                      <td>{loan_ref}</td>
                      <td>{product_name}</td>
                      <td>{accurateQuantifier(loan_tenure, "day")}</td>
                      <td>{repayment_type}</td>
                      <td>
                        {currencyFormatter(Number(loan_amount), currency)}
                      </td>
                      <td>
                        {currencyFormatter(Number(loan_amount_repay), currency)}
                      </td>
                      <td>
                        {loan_effective_date
                          ? loan_effective_date.substring(0, 10)
                          : ""}
                      </td>
                      <td>{loan_due_date.substring(0, 10)}</td>
                      <td>
                        <span className={attachClassNameToTag(status)}>
                          {attachClassNameToTag(status)}
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </MDBTableBody>
          </MDBTable>
        </>
      );
    }
  }

  return (
    <div className="dataTables_wrapper">
      <div
        id="activeEcosystem"
        className="Disbursement table-view animated speed-1x fadeInRight"
      >
        <div
          style={{
            height: "25rem",
            overflowY: "scroll",
          }}
        >
          {RenderedComponent}
        </div>
      </div>
    </div>
  );
};

export default Disbursement;
